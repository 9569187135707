import { useContext } from 'react'
import { useSelector } from 'react-redux'
import PageContext from '@contexts/pageContext'

const editFlags = (flags, showEditFlagsModal, type) => new Promise((resolve, reject) => {
  if (showEditFlagsModal){
    const payload = {
      flags: flags[type] || [],
      type,
    }

    showEditFlagsModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showEditFlagsModal not defined in PageContext callbacks'))
})

const useFlags = () => {
  const { callbacks } = useContext(PageContext)
  const { showEditFlagsModal } = callbacks || {}

  const { result: userFlagsV3 } = useSelector(reduxState => reduxState.userFlagsV3)
  const { result: providerFlagsV3 } = useSelector(reduxState => reduxState.providerFlagsV3)
  const { result: zoneFlagsV3 } = useSelector(reduxState => reduxState.zoneFlagsV3)
  const { result: clientFlagsV3 } = useSelector(reduxState => reduxState.clientFlagsV3)

  const flags = {
    provider: providerFlagsV3,
    user: userFlagsV3,
    zone: zoneFlagsV3,
    client: clientFlagsV3,
  }

  return {
    callbacks: {
      editFlags: type => editFlags(flags, showEditFlagsModal, type),
    },
  }
}

export default useFlags
