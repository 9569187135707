import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import PageContext from '@contexts/pageContext'

import { Box, ModalContext } from '@campaignhub/suit-theme'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import validateEntityStateCustomFields from '@functions/validateEntityStateCustomFields'
import { requiredFields } from '@models/Client/client'

import EditFlagsModal from '@modals/EditFlagsModal'

import GeneralDetails from './components/GeneralDetails'
import Preferences from './components/Preferences'
import ZoneDetails from './components/ZoneDetails'
import ClientNotes from './components/ClientNotes'
import IntegrationDetails from './components/IntegrationDetails'
import AccountManagerDetails from './components/AccountManagerDetails'

const defaultState = {
  showEditFlagsModal: false,
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    EditFlagsModal: {
      closeModal: () => setState({ showEditFlagsModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const ClientDetails = (props) => {
  const { clientForm, customErrors, callback } = props
  const { setCustomErrors } = callback

  const { entityState } = clientForm

  const [state, setState] = useSetState(defaultState)
  const { showEditFlagsModal } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = {
    callbacks: {
      showEditFlagsModal: (payload) => {
        setModalData('EditFlagsModal', payload)
        setState({ showEditFlagsModal: true })
      },
    },
  }

  useEffect(() => {
    setCustomErrors(validateEntityStateCustomFields(entityState, requiredFields))
  }, [entityState])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <Box flexDirection="column">
          <GeneralDetails
            clientForm={clientForm}
            customErrors={customErrors}
            callbacks={{ setCustomErrors }}
          />
          <Preferences clientForm={clientForm} />
          <AccountManagerDetails clientForm={clientForm} />
          <ZoneDetails clientForm={clientForm} />
          <ClientNotes clientForm={clientForm} />
          <IntegrationDetails clientForm={clientForm} />
        </Box>
        <EditFlagsModal
          callbacks={callbacks('EditFlagsModal', setState)}
          showModal={showEditFlagsModal}
          form={clientForm}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

ClientDetails.propTypes = {
  clientForm: PropTypes.object.isRequired,
  customErrors: PropTypes.array,
  callback: PropTypes.object,
}

export default ClientDetails
