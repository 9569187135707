import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, FormField, DashboardModule,
} from '@campaignhub/suit-theme'

const ClientNotes = (props) => {
  const { clientForm } = props

  const {
    entityState: {
      production_note,
      appointment_note,
    },
    handlers,
  } = clientForm

return (
  <DashboardModule title="Client Notes" boxProps={{ marginBottom: 'large' }}>
    <Box padding="large" flexDirection={['column', 'row']}>
      <Box marginBottom="large" width={['100%', 'calc(50% - 4px)']}>
        <FormField
          boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
          label="Appointment Notes"
        >
          <textarea
            name="appointment_note"
            value={appointment_note}
            style={{ height: 80, resize: 'none', width: '100%' }}
            {...handlers}
          />
        </FormField>
      </Box>
      <Box marginBottom="large" width={['100%', 'calc(50% - 4px)']}>
        <FormField
          boxProps={{ flex: 1 }}
          label="Production Notes"
        >
          <textarea
            name="production_note"
            value={production_note}
            style={{ height: 80, resize: 'none', width: '100%' }}
            {...handlers}
          />
        </FormField>
      </Box>
    </Box>
  </DashboardModule>
  )
}

ClientNotes.propTypes = {
  clientForm: PropTypes.object,
}

export default ClientNotes
