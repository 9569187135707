import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

import {
  Box, Image, ListItem, StatusBadge, Tag, Text,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy'

import Icons from '@components/Icons'
import { faCheckCircle, faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import customMetaData from '@functions/customMetaData'

const defaultState = {
  customMetaValues: {},
  VSIRComment: null,
}

const GridItem = (props) => {
  const {
    badgeColor,
    badgeText,
    callbacks,
    content,
    fileUrl,
    iconName,
    link,
    multipleSelection,
    name,
    selected,
    showDescription,
    showDetails,
  } = props

  const [state, setState] = useSetState(defaultState)
  const { customMetaValues, VSIRComment } = state

  const { selectContent } = callbacks || {}

  const { assetComments } = useSelector(reduxState => reduxState.entities)

  useEffect(() => {
    if (fileUrl){
      const xhr = new XMLHttpRequest()
      xhr.open('HEAD', fileUrl)
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200){
          const meta = {}
          xhr.getAllResponseHeaders()
            .trim()
            .split(/[\r\n]+/)
            .map(value => value.split(/: /))
            .forEach((keyValue) => {
              if (keyValue[0].includes('x-ms-meta')){
                meta[keyValue[0].trim()] = keyValue[1].trim()
              }
            })
          setState({ customMetaValues: { ...meta } })
        }
      }
      xhr.send(null)
    }
  }, [fileUrl])

  useEffect(() => {
    const VSIRCommentId = content?.comments?.reverse()?.find((comment) => 
      assetComments[comment]?.details?.find((detail) => detail?.entityFieldType?.name === 'VSIRStatus')
    )
  
    setState({ VSIRComment: Object.values(assetComments)?.find((assetComment) => assetComment.id === VSIRCommentId)?.comment || null })
  }, [])

  return (
    <Box
      border={showDetails ? '1px solid' : 0}
      borderColor="lineColor"
      borderRadius={5}
      flexDirection="column"
      key={content.id}
    >
      <Image
        backgroundPosition="center center"
        borderRadius={showDetails ? '5px 5px 0 0' : '5px'}
        boxProps={{ backgroundColor: 'hoverLightGrey' }}
        height={140}
        url={content?.file?.previewGeneratedAt ? content.previewUrl : ''}
        width={'100%' || 200}
      >        
        <Box
          as={multipleSelection ? null : fileUrl ? 'a' : null}
          height="100%"
          href={fileUrl}
          onClick={() => (multipleSelection ? selectContent() : null)}
          position="relative"
          style={{ cursor: multipleSelection ? 'pointer' : '' }}
          target="_blank"
        >
          {VSIRComment && (
            <Box
              alignItems="start"
              color="bodyFontColor"
              display="grid"
              height="100%"
              justifyContent="end"
              position="absolute"
              width="100%"
            >
              <Tag boxProps={{ width: 'fit-content', fontSize: 'xsmall', marginTop: 'small' }}>
                {VSIRComment}
              </Tag>
            </Box>
          )}
          {!content?.file?.previewGeneratedAt && (
            <Box
              alignItems="center"
              color="bodyFontColor"
              display="grid"
              height="100%"
              justifyContent="center"
              position="absolute"
              width="100%"
            >
              <Icons name={iconName} size={50} width="100%" />
            </Box>
          )}
          {selected && (
            <Box
              alignItems="center"
              backgroundColor="rgba(0, 0, 0, 0.4)"
              borderRadius={showDetails ? '5px 5px 0 0' : '5px'}
              color="bodyFontColor"
              display="grid"
              justifyContent="center"
              width="100%"
              zIndex={1}
            >
              <Box
                alignItems="center"
                borderRadius={20}
                color="white"
                fontSize="xsmall"
                justifyContent="center"
                width="100%"
              >
                <FontAwesomeIcon icon={faCheckCircle} size="3x" />
              </Box>
            </Box>
          )}
        </Box>
      </Image>
      {showDetails && (
        <Box flexDirection="column">
          <Link to={link} style={{ textDecoration: 'none' }}>
            <ListItem
              boxProps={{ padding: 'large' }}
              flexDirection="column"
              showAngleIcon
            >
              <Text variant="ellipsis" fontSize="medium" fontWeight="600" width={180} title={name}>
                {name}
              </Text>
              <Box flexDirection="row" marginTop="medium">
                <Box alignItems="center" justifyContent="flexStart" width="auto" paddingRight="medium">
                  <Icons name={iconName} />
                </Box>
                <StatusBadge color={badgeColor} text={badgeText} />
              </Box>
            </ListItem>
            {showDescription && (
              <ListItem
                boxProps={{
                  borderTop: '1px solid',
                  padding: 'large',
                }}
                disableHover
                flexDirection="column"
              >
                <Box
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Text fontSize="small">{content?.description}</Text>
                  {Object.keys(customMetaValues).length ? (
                    <Tooltip
                      html={(
                        <div style={{ textAlign: 'left' }}>
                          <Text fontSize="small">File Type: {content?.file?.fileType.name}</Text>
                          {Object.entries(customMetaData).map(([key, value]) => (
                            Object.keys(customMetaValues).find(x => x.includes(key)) && (
                              <Text fontSize="small">
                                {value}: {key === 'datetimeoriginal'
                                  ? DateTime.fromFormat(customMetaValues[Object.keys(customMetaValues)
                                    .find(x => x.includes(key))], 'yyyy:MM:dd HH:mm:ss')
                                    .toFormat('MM/dd/yy HH:mm')
                                  : key === 'size' ? `${(customMetaValues[Object.keys(customMetaValues).find(x => x.includes(key))] / (1024 * 1024)).toFixed(2)} MB`
                                  : customMetaValues[Object.keys(customMetaValues).find(x => x.includes(key))] }
                              </Text>
                            )
                          ))}
                        </div>
                      )}
                      trigger="mouseenter"
                      arrow
                      arrowSize="regular"
                      duration={0}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} color="black" size="md" />
                    </Tooltip>
                  ) : null}
                </Box>
              </ListItem>
            )}
          </Link>
        </Box>
      )}
      {!showDetails && (
        <Text fontSize="small" fontWeight={600} paddingTop="medium">
          {content.fileName}
        </Text>
      )}
    </Box>
  )
}

GridItem.propTypes = {
  badgeColor: PropTypes.string,
  badgeText: PropTypes.string,
  callbacks: PropTypes.object,
  content: PropTypes.object,
  fileUrl: PropTypes.string,
  iconName: PropTypes.string,
  link: PropTypes.string,
  multipleSelection: PropTypes.bool,
  name: PropTypes.string,
  selected: PropTypes.bool,
  showDescription: PropTypes.bool,
  showDetails: PropTypes.bool,
}

export default GridItem
