import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'
import {
  Box, Button, ModalContext, SidebarModal, SidebarNoticeBox, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import custom from '@styles/custom.module.scss'

const defaultState = {
  enabled: [],
}

const EditFlagsModal = (props) => {
  const { callbacks, modalKey, showModal, form } = props
  const { closeModal } = callbacks

  const [state, setState] = useSetState(defaultState)
  const { enabled } = state

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { flags, type } = digObject(modalData, `${modalKey}`, {})

  const {
    entityState: {
      flags: generalFlags,
      provider = {},
    },
    setEntityState,
  } = form

  const {
    flags: providerFlags = [],
  } = provider

  useEffect(() => {
    setState({ enabled: (type === 'provider' ? providerFlags : generalFlags) || [] })
  }, [form])

  const updateForm = (updatedFlags) => {
    if (type === 'provider'){
      provider.flags = updatedFlags || []
      setEntityState({ provider })
    } else {
      setEntityState({ flags: updatedFlags || [] })
    }
  }

  const updateEnabledFlags = (flag, checked) => {
    const temp = (checked ? [...enabled, flag] : enabled.filter(x => x !== flag)) || []

    setState({ enabled: temp })
    updateForm(temp)
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header callbacks={callbacks} title="Update" titleSecondLine={`${type[0].toUpperCase() + type.substring(1)} Flags`} />
      <SidebarModal.Content>
        <SidebarNoticeBox
          boxProps={{
            marginBottom: 'medium',
            backgroundColor: 'lightcyan!important',
            borderColor: 'rgb(125, 208, 215, 0.5)!important',
          }}
        >
          <SidebarNoticeBox.Title>
            Remember to Save Changes
          </SidebarNoticeBox.Title>
          <SidebarNoticeBox.Paragraph>
            Go back to the main modal and click Save.
          </SidebarNoticeBox.Paragraph>
        </SidebarNoticeBox>
        <Box flexDirection="column" marginBottom="large" height="100%" border="1px solid" borderColor="lineColor" borderRadius={5} padding="large">
          {flags?.length > 0 ? flags?.map(x => (
            <Box key={x.value} flexDirection="row" alignItems="center" marginBottom="medium">
              <input
                name={x.value}
                type="checkbox"
                className={custom.checkbox}
                checked={enabled.includes(x.value)}
                onChange={e => updateEnabledFlags(x.value, e.target.checked)}
              />
              <Text fontSize="xsmall" marginLeft="medium" color="bodyFontLightColor" title={x?.description}>
                {type === 'client' ? x.name.replace(/([A-Z])/g, ' $1').trim() : x.name}
              </Text>
            </Box>
          )) : (
            <Text fontSize="xsmall" marginLeft="medium" color="bodyFontLightColor" textAlign="center">
              No available flags
            </Text>
          )}
        </Box>
      </SidebarModal.Content>
      <SidebarModal.Footer>
        <Button
          buttonStyle="secondaryUtility"
          onClick={() => closeModal()}
          size="medium"
        >
          Close
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

EditFlagsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
  form: PropTypes.object,
}

EditFlagsModal.defaultProps = {
  modalKey: 'EditFlagsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditFlagsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
