import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import {
  Box, FormField, DashboardModule, Tag, Text,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/pro-light-svg-icons'

import useFlags from '@hooks/useFlags'

import classifications from '@functions/classifications'

const GeneralDetails = (props) => {
  const { clientForm, customErrors } = props

  const { result: statesV3 } = useSelector(reduxState => reduxState.statesV3)
  const { result: clientFlagsV3 } = useSelector(reduxState => reduxState.clientFlagsV3)

  const {
    entityState: {
      name,
      business_name,
      brand,
      address: {
        address,
        suburb,
        postcode,
        state,
      },
      phone,
      email,
      classification,
      flags,
    },
    handlers,
    setEntityState,
  } = clientForm

  const { callbacks: { editFlags } } = useFlags()

  return (
    <DashboardModule title="General Information" boxProps={{ marginBottom: 'large' }}>
      <Box flexDirection="column" padding="large">
        <Box flexDirection={['column', 'row']} marginBottom="large">
          <FormField
            boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
            label="Name"
            errorMessage={customErrors.name}
          >
            <input name="name" type="text" value={name} {...handlers} />
          </FormField>
          <FormField
            boxProps={{ flex: 1 }}
            label="Business Name"
          >
            <input name="business_name" type="text" value={business_name} {...handlers} />
          </FormField>
        </Box>
        <Box flexDirection={['column', 'row']} marginBottom="large">
          <FormField
            boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
            label="Brand"
          >
            <input name="brand" type="text" value={brand} {...handlers} />
          </FormField>
          <FormField
            boxProps={{ flex: 1 }}
            label="Classification"
          >
            <select
              data-validate-field-on="change"
              name="classification"
              value={classification}
              onChange={(e) => { setEntityState({ classification: e.target.value }) }}
              {...handlers}
            >
              <option value="">Please Select...</option>
              {Object.values(classifications).map(x => <option value={x.name} key={x.id}>{x.name}</option>)}
            </select>
          </FormField>
        </Box>
        <Box flexDirection={['column', 'row']} marginBottom="large">
          <FormField
            boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
            label="Address"
          >
            <input name="address.address" type="text" value={address} {...handlers} />
          </FormField>
          <FormField
            boxProps={{ flex: 1 }}
            label="Suburb"
          >
            <input name="address.suburb" type="text" value={suburb} {...handlers} />
          </FormField>
        </Box>
        <Box flexDirection={['column', 'row']} marginBottom="large">
          <FormField
            boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
            label="State"
          >
            <select
              data-validate-field-on="change"
              name="address.state"
              value={state}
              onChange={(event) => {
                address.state = event.target.value
                setEntityState({ address })
              }}
              {...handlers}
            >
              <option value="">Please Select...</option>
              {Object.values(statesV3).filter(x => x !== 'Unknown').map(stateV3 => <option value={stateV3} key={stateV3}>{stateV3}</option>)}
            </select>
          </FormField>
          <FormField
            boxProps={{ flex: 1 }}
            label="Post Code"
          >
            <input name="address.postcode" type="text" value={postcode} {...handlers} />
          </FormField>
        </Box>
        <Box flexDirection={['column', 'row']} marginBottom="large">
          <FormField
            boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
            label="Phone"
          >
            <input name="phone" type="text" value={phone} {...handlers} />
          </FormField>
          <FormField
            boxProps={{ flex: 1 }}
            label="Email"
            errorMessage={customErrors.email}
          >
            <input name="email" type="text" value={email} {...handlers} />
          </FormField>
        </Box>
        <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']}>
          <FormField
            boxProps={{ flex: 1, paddingBottom: ['large', 0] }}
            label={(
              <Box flexDirection="row" alignItems="center">
                <Text
                  color="bodyFontLightColor"
                  fontSize="xsmall"
                  letterSpacing="1px"
                  marginRight="small"
                >
                  Flags
                </Text>
                <Tag
                  boxProps={{
                    backgroundColor: '#43ac6a!important',
                    fontSize: 'xsmall',
                    onClick: () => { editFlags('client') },
                    width: 'fit-content',
                  }}
                >
                  <Text color="white" fontSize={9} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faPlus} />
                  </Text>
                </Tag>
              </Box>
            )}
          >
            <Box flexWrap="wrap" gridGap="small">
              {flags.map(flag => (
                <Tag
                  key={flag}
                  boxProps={{
                    width: 'fit-content',
                    fontSize: 'xsmall',
                    onClick: () => {
                      setEntityState({ flags: flags.filter(x => x !== flag) })
                    },
                  }}
                >
                  <Text color="bodyFontLightColor" style={{ cursor: 'pointer' }} title={clientFlagsV3.find(x => x.value === flag)?.description}>
                    {clientFlagsV3.find(x => x.value === flag)?.name?.replace(/([A-Z])/g, ' $1').trim()} &nbsp;<FontAwesomeIcon icon={faTimes} />
                  </Text>
                </Tag>
              ))}
            </Box>
          </FormField>
        </Box>
      </Box>
    </DashboardModule>
  )
}

GeneralDetails.propTypes = {
  clientForm: PropTypes.object,
  customErrors: PropTypes.array,
}

export default GeneralDetails
