import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useSetState } from '@campaignhub/react-hooks'
import { Box, Button, FormField, Text } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import useAssetComment from '@hooks/useAssetComment'
import { getEntityByName } from '@functions/getEntityByName'

const defaultState = {
  selectedStatusId: 0,
}

const VSIRStatus = () => {
  const [state, setState] = useSetState(defaultState)
  const { selectedStatusId } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { assetComments, assetCommentTypes, vsirStatuses } = entities

  const { selectedAsset } = useContext(PageContext)

  useEffect(() => {
    const VSIRStatusDetail = selectedAsset?.comments?.flatMap((comment) =>
      assetComments[comment]?.details?.filter((detail) => detail?.entityFieldType?.name === 'VSIRStatus')
    )?.reverse()[0]

    if (VSIRStatusDetail && selectedStatusId === 0){
      const VSIRID = Object.values(vsirStatuses)?.find((status) => status.name === JSON.parse(VSIRStatusDetail.value).value)?.id
      setState({ selectedStatusId: VSIRID || 0 })
    }
  }, [selectedAsset])

  const {
    callbacks: { createAssetComment: createFn },
    creating,
  } = useAssetComment()

  const saveVSIRStatus = () => {
    if (!selectedStatusId) return

    const comment = {
      assetId: selectedAsset?.id,
      assetCommentTypeId: getEntityByName(assetCommentTypes, 'Studio')?.id,
      comment: vsirStatuses[selectedStatusId].description,
      assetCommentDetails: [{
        fieldName: 'VSIRStatus',
        value: `{ "value": "${vsirStatuses[selectedStatusId].name}" }`,
      }],
    }

    createFn(comment).then(({ success, errors }) => {
      if (!success){
        toast.warning(errors[0])
      }

      return success
    })
  }

  return (
    <Box flexDirection="column">
      <Text fontSize="small" marginBottom="small">VERIFICATION STATUS</Text>
      <FormField>
        <select
          onChange={(e) => setState({ selectedStatusId: e.target.value }) }
          value={selectedStatusId}
        >
          <option value={0} disabled selected hidden>Please Select...</option>
          {Object.values(vsirStatuses).map(status => (
            <option key={status.id} value={status.id}>
              {status.description}
            </option>
          ))}
        </select>
        <Button
          buttonStyle="primaryCreate"
          loading={creating}
          marginLeft="auto"
          marginTop="medium"
          onClick={() => saveVSIRStatus()}
          size="medium"
          width="auto"
        >
          Save
        </Button>
      </FormField>
    </Box>
  )
}

export default VSIRStatus
