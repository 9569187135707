import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import { toast } from 'react-toastify'

import { useSetState } from '@campaignhub/react-hooks'
import { Box, Button, FormField, Text } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import Comment from '@suiteThemeCustom/Comment'
import useAssetComment from '@hooks/useAssetComment'
import { getEntityByName } from '@functions/getEntityByName'

const defaultState = {
  proofingNote: '',
}

const ProofingNotes = () => {
  const [state, setState] = useSetState(defaultState)
  const { proofingNote } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { assetCommentTypes } = entities

  const { currentUser, proofingNotes, selectedAsset } = useContext(PageContext)

  const {
    callbacks: { createAssetComment: createFn },
    creating,
  } = useAssetComment()

  const addProofingNote = () => {
    if (!proofingNote.trim()?.length) return

    const comment = {
      assetId: selectedAsset?.id,
      assetCommentTypeId: getEntityByName(assetCommentTypes, 'ProofingNote')?.id,
      comment: proofingNote,
      assetCommentDetails: [{
        fieldName: 'Commenter',
        value: `{ "value": "${currentUser.firstName} ${currentUser.lastName}" }`,
      }],
    }

    createFn(comment).then(({ success, errors }) => {
      if (!success){
        toast.warning(errors[0])
      }
      setState({ proofingNote: '' })
      return success
    })
  }

  return (
    <Box flexDirection="column">
      <Text fontSize="small" marginBottom="small">PROOFING NOTES</Text>
      {proofingNotes?.length > 0 ? (
        <Box
          border="1px solid"
          borderColor="lineColor"
          borderRadius={5}
          flexDirection="column"
          marginBottom="medium"
          maxHeight="30vh"
          overflow="auto"
          paddingTop="large"
          paddingX="large"
        >
          {proofingNotes.sort((a, b) => (a.id > b.id ? -1 : 1)).map((comment) => {
            const commenter = comment.details.find(x => x.entityFieldType.name === 'Commenter').value
            return (
              <Comment
                commentBody={comment.comment}
                commentTime={DateTime.fromISO(comment.createdAt, { zone: currentUser.timeZone }).toFormat('dd LLLL y, hh:mm a')}
                fullName={commenter ? JSON.parse(commenter).value : ''}
                hideImage
              />
            )
          })}
        </Box>
    ) : null}
      <FormField>
        <textarea
          name="noteContent"
          onChange={e => setState({ proofingNote: e.target.value })}
          placeholder="Type your proofing notes here..."
          style={{ height: 80, resize: 'none', width: '100%' }}
          value={proofingNote}
        />
        <Button
          buttonStyle="primaryCreate"
          loading={creating}
          marginLeft="auto"
          marginTop="medium"
          onClick={() => addProofingNote()}
          size="medium"
          width="auto"
        >
          Add
        </Button>
      </FormField>
    </Box>
  )
}

export default ProofingNotes
