import useReduxAction from '@hooks/useReduxAction'
import useUserRoles from '@hooks/useUserRoles'
import useServiceGroups from '@hooks/useServiceGroups'
import useServiceJobStatuses from '@hooks/useServiceJobStatuses'
import useActivityLogTypes from '@hooks/useActivityLogTypes'
import useNotificationTypes from '@hooks/useNotificationTypes'

const OnSite = (props) => {
  const { children } = props

  useReduxAction('assetCommentTypes', 'loadAssetCommentTypes', {})
  useReduxAction('employmentTypes', 'loadEmploymentTypesV3', {})
  useReduxAction('resourceTypes', 'loadResourceTypes', {})
  useReduxAction('serviceTypes', 'loadServiceTypesV3', {})
  useReduxAction('vsirStatuses', 'loadVSIRStatuses', {})
  useReduxAction('wordReplacements', 'loadWordReplacements', {})

  useUserRoles({ performHttpRequests: true })
  useServiceJobStatuses({ performHttpRequests: true })
  useServiceGroups({ performHttpRequests: true })
  useActivityLogTypes({ performHttpRequests: true })
  useNotificationTypes({ performHttpRequests: true })
  return children
}

export default OnSite
